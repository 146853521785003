<template>
  <div class="editor-body">
    <div class="editor-content">
      <div  id="editor—wrapper" style="border: 1px solid #ccc;">
        <Toolbar
          id="toolbar-container"
          style="border-bottom: 1px solid #ccc"
          :editor="editorRef"
          :defaultConfig="toolbarConfig"
        />
        <Editor
          id="editor-container"
          style="height: 300px; overflow-y: hidden;"
          v-model="pageState.valueHtml"
          :defaultConfig="editorConfig"
          @onCreated="handleCreated"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, shallowRef, onMounted, defineEmits, defineProps, reactive, defineExpose } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { getOssUploadConfig } from '@/services/oss'
import OSS from 'ali-oss'
import moment from 'moment'
import AudioUploader from './components/audio'
import { audioAttachment, renderAudioAttachment } from './components/attachment'
import { Boot } from '@wangeditor/editor'
import { message } from 'ant-design-vue'

const props = defineProps({
  readOnly: {
    type: Boolean,
    default: false
  },
  content: {
    type: String,
    default: ''
  }
})

const messageKey = 'upload'

const pageState = reactive({
  valueHtml: props.content, // 内容 HTML
  current: {}
})

const emits = defineEmits(['onSave'])

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

const ossConfig = {
  domain: null,
  client: null
}

const getOssConfig = async () => {
  const { upload_config: data } = await getOssUploadConfig()
  ossConfig.client = new OSS({
    accessKeyId: data.access_key_id,
    accessKeySecret: data.access_key_secret,
    region: data.region,
    bucket: data.bucket,
    stsToken: data.sts_token
  })
  ossConfig.domain = data.download_domain
  ossConfig.basePath = data.base_path
}

const uploadPath = (path, file) =>
  `${path}/${moment().format('YYYYMMDD')}/${file.name.split('.')[0]}-${
    file.uid
  }.${file.type.split('/')[1]}`

const uploadToOss = (path, file) => {
  const storeAs = uploadPath(ossConfig.basePath, file)

  return new Promise((resolve, reject) => {
    ossConfig.client
      .multipartUpload(storeAs, file)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
const customBootConfig = {
  key: 'customUploadAudio',
  factory () {
    return new AudioUploader({
      async uploadHandler (file, insertFn) {
        // file 即选中的文件
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          // 使用ossupload覆盖默认的上传方法
          message.loading({ key: messageKey, content: '上传中...' })
          uploadToOss('/appreciation/audio', file).then(data => {
            const url = new URL(data.res.requestUrls)
            file.url = ossConfig.domain + url.pathname
            insertFn(file.url)
            message.success({ key: messageKey, content: '上传成功', duration: 2 })
          }).catch(e => {
            console.error('上传失败')
            console.error(e)
            message.error({ key: messageKey, content: '上传失败', duration: 2 })
          })
        }
      }
    })
  }
}

const renderElemConf = {
  type: 'audio', // 新元素 type ，重要！！！
  renderElem: renderAudioAttachment
}

try {
  Boot.registerMenu(customBootConfig)
} catch (e) {
  console.error(e)
}

// Boot.registerPlugin(audioAttachment)
// Boot.registerRenderElem(renderElemConf)

const customConfig = {
  MENU_CONF: {}
}
customConfig.MENU_CONF.uploadImage = {
  async customUpload (file, insertFn) {
    // file 即选中的文件
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      message.loading({ key: messageKey, content: '上传中...' })
      uploadToOss('/appreciation/image', file).then(data => {
        const url = new URL(data.res.requestUrls)
        file.url = ossConfig.domain + url.pathname
        insertFn(file.url)
        message.success({ key: messageKey, content: '上传成功', duration: 2 })
      }).catch(e => {
        console.error('上传失败')
        console.error(e)
        message.error({ key: messageKey, content: '上传失败', duration: 2 })
      })
    }
  }
}
customConfig.MENU_CONF.uploadVideo = {
  allowedFileTypes: ['video/mp4', 'video/mov'],
  async customUpload (file, insertFn) {
    // file 即选中的文件
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      message.loading({ key: messageKey, content: '上传中...' })
      uploadToOss('/appreciation/video', file).then(data => {
        const url = new URL(data.res.requestUrls)
        file.url = ossConfig.domain + url.pathname
        insertFn(file.url)
        message.success({ key: messageKey, content: '上传成功', duration: 2 })
      }).catch(e => {
        console.error('上传失败')
        console.error(e)
        message.error({ key: messageKey, content: '上传失败', duration: 2 })
      })
    }
  }
}
customConfig.MENU_CONF.fontSize = {
  fontSizeList: [
    '12px',
    '14px',
    { name: '16px', value: '16px' },
    '18px',
    '20px',
    '24px',
    '32px',
    '40px',
    '48px'
  ]
}

// 模拟 ajax 异步获取内容
onMounted(async () => {
  await getOssConfig()
})

const toolbarConfig = {}
toolbarConfig.excludeKeys = [
  'fontSize',
  'insertLink',
  'insertVideo',
  'insertImage',
  'insertTable',
  'codeBlock',
  'blockquote'
]
// toolbarConfig.insertKeys = {
//   index: 25,
//   keys: ['customUploadAudio']
// }

let editorConfig = props.id ? { placeholder: 'Loading...' } : { placeholder: '请输入内容...' }
editorConfig = { ...editorConfig, ...customConfig }

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

const handleSaveText = () => {
  emits('onSave', pageState.valueHtml)
}

const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
  // 设置只读
  if (props.readOnly) {
    editor.disable()
  }
}

const getContent = () => pageState.valueHtml

defineExpose({
  handleSaveText,
  getContent
})
</script>

<style lang="less" scoped>
.functionArea {
  // position: absolute;
  padding: 10px;
  // left: 50%;
  // transform: translateX(-50%);
  // top: 400px;
}
.editor-body {
  position: relative;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
}
#editor—wrapper {
  border: 1px solid #ccc;
  z-index: 999;
}
#toolbar-container {
  border-bottom: 1px solid #ccc;
  z-index: 1;
}
#editor-container {
  height: 500px;
}
</style>
