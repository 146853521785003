<script setup>
import { computed, ref, toRaw, defineEmits, defineProps } from 'vue'
import UploadSong from './UploadSong.vue'
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { ColorList } from './config'

const emit = defineEmits(['onOk', 'onEditOk', 'cancel'])

const props = defineProps({
  visible: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  isEdit: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  value: {
    type: Object,
    default: () => {
      return {}
    }
  },
  aliasConfig: {
    type: Object,
    default: () => {
      return {
        url: 'file_url',
        name: 'file_name',
        md5: 'file_md5',
        size: 'file_size'
      }
    }
  }
})
const colorList = ColorList
const visibleComputed = computed(() => props.visible)
const isEditComputed = computed(() => props.isEdit)

const current = ref(props.value)

const labelCol = { span: 5 }
const wrapperCol = { span: 17 }

const formRef = ref()

const handleSelectSongOk = info => {
  current.value = { ...toRaw(current.value), ...info }
}

const handleDelete = item => {
  const index = current.value.para_info.indexOf(item)
  const oldCurrent = ref({ ...toRaw(current.value) })
  const list = [...toRaw(oldCurrent.value.para_info)]
  list.splice(index, 1)
  oldCurrent.value.para_info = list
  current.value = oldCurrent.value
}

const handleAddParaInfo = () => {
  if (!current.value.para_info) {
    current.value.para_info = []
  }
  current.value.para_info.push({})
}

const handleMoveItem = (fromIdx, toIdx) => {
  const array = current.value.para_info
  const [minAvailable, maxAvailable] = [0, array.length - 1]
  if (
    fromIdx >= minAvailable &&
    toIdx >= minAvailable &&
    fromIdx <= maxAvailable &&
    toIdx <= maxAvailable
  ) {
    array.splice(
      toIdx,
      1,
      ...array.splice(fromIdx, 1, array[toIdx])
    )
  }
}

const handleCancel = () => {
  emit('cancel')
}

const handleOk = () => {
  const info = ref(toRaw(current.value))
  if (info.value.para_info) {
    for (let i = 0; i < info.value.para_info.length; i++) {
      if (!info.value.para_info[i].name) {
        message.error('分段名称不能为空')
        throw new Error('分段名称不能为空')
      } else if (info.value.para_info[i].start_time === null) {
        message.error('起始点不能为空')
        throw new Error('起始点不能为空')
      } else if (!info.value.para_info[i].color) {
        message.error('颜色不能为空')
        throw new Error('颜色不能为空')
      }
    }
  }
  if (!info.value[props.aliasConfig.name]) {
    message.error('请选择歌曲上传')
    throw new Error('请选择歌曲上传')
  }
  if (isEditComputed.value) {
    emit('onEditOk', info.value)
  } else {
    emit('onOk', info.value)
  }
  handleCancel()
}

</script>

<template>
  <a-modal
    :title="isEdit ? '编辑配置' : '新增配置'"
    :visible="visibleComputed"
    :maskClosable="false"
    width="1000px"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form
      v-model:model="current"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-form-item label="MP3歌曲">
        <UploadSong @ok="handleSelectSongOk" :aliasConfig="props.aliasConfig" />
      </a-form-item>
      <a-form-item label="歌曲名称">
        <a-input v-model:value="current[props.aliasConfig.name]"></a-input>
      </a-form-item>
      <a-form-item label="歌曲地址">
        <a-input
          v-model:value="current[props.aliasConfig.url]"
          :disabled="true"
        ></a-input>
      </a-form-item>
      <a-form-item label="歌曲md5">
        <a-input
          v-model:value="current[props.aliasConfig.md5]"
          :disabled="true"
        ></a-input>
      </a-form-item>
      <a-form-item label="歌曲文件大小">
        <a-input
          v-model:value="current[props.aliasConfig.size]"
          :disabled="true"
        ></a-input>
      </a-form-item>
      <a-row
        size="small"
        v-for="(pItem, pIndex) in current.para_info"
        :key="pIndex"
      >
        <a-col :span="6" :offset="3">
          <a-form-item
            label="分段名"
            :labelCol="{ span: 8 }"
            :wrapperCol="{ span: 14 }"
            :rules="[{ required: true, message: '请输入分段名' }]"
          >
            <a-input
              v-model:value="pItem.name"
              style="width: 100%; margin-right: 10px"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item
            :labelCol="{ span: 10 }"
            :wrapperCol="{ span: 12 }"
            label="分段起始点"
            :rules="[{ required: true, message: '请输入起始点' }]"
          >
            <a-input-number v-model:value="pItem.start_time" :step="0.1" :min="0"  /><span> 秒</span>
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item
            label="分段颜色"
            :labelCol="{ span: 8 }"
            :wrapperCol="{ span: 12 }"
            :rules="[{ required: true, message: '请选择颜色' }]"
          >
            <a-select v-model:value="pItem.color" :options="colorList">
              <template #option="{ label, tag }">
                <a-tag style="height: 10px" :color="tag"></a-tag>
                {{ label }}
              </template>
            </a-select>
          </a-form-item>
        </a-col>
        <UpCircleOutlined
          :disabled="pIndex === 0"
          @click="handleMoveItem(pIndex, pIndex - 1)"
          class="delete-button"
        />
        <DownCircleOutlined
          :disabled="pIndex === current.para_info.length - 1"
          @click="handleMoveItem(pIndex, pIndex + 1)"
          class="delete-button"
        />
        <MinusCircleOutlined
          @click="handleDelete(pItem)"
          class="delete-button"
        />
      </a-row>
      <a-form-item :wrapperCol="{ offset: 5, span: 17 }">
        <a-button style="width: 100%" @click="handleAddParaInfo">
          <PlusOutlined />新增
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style scoped>
.delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #888;
  transition: all 0.3s;
  margin-right: 3px;
}
.delete-button:hover {
  color: #777;
}
.delete-button[disabled='true'] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
