import { DomEditor, IDomEditor, SlateElement } from '@wangeditor/editor'
import { h, VNode } from 'snabbdom'

function audioAttachment (editor) {
  const { isInline, isVoid } = editor
  const newEditor = editor

  newEditor.isInline = elem => {
    const type = DomEditor.getNodeType(elem)
    if (type === 'audio') return true // 针对 type: attachment ，设置为 inline
    return isInline(elem)
  }

  newEditor.isVoid = elem => {
    const type = DomEditor.getNodeType(elem)
    if (type === 'audio') return true // 针对 type: attachment ，设置为 void
    return isVoid(elem)
  }

  return newEditor // 返回 newEditor ，重要！！！
}

/**
 * 渲染“附件”元素到编辑器
 * @param elem 附件元素，即上文的 myResume
 * @param children 元素子节点，void 元素可忽略
 * @param editor 编辑器实例
 * @returns vnode 节点（通过 snabbdom.js 的 h 函数生成）
 */
function renderAudioAttachment (elem, children, editor) {
  // 获取“附件”的数据，参考上文 myResume 数据结构
  const { fileName = '', link = '' } = elem

  // 附件 icon 图标 vnode
  const iconVnode = h(
    // HTML tag
    'img',
    // HTML 属性
    {
      props: { src: 'xxxx.png' },
      style: { width: '1em', marginRight: '0.1em' } // HTML style ，驼峰式写法
    }
    // img 没有子节点，所以第三个参数不用写
  )

  // 附件元素 vnode
  const attachVnode = h(
    'audio',
    {
      props: { src: 'xxxx.png' }, // HTML 属性，驼峰式写法
      style: { display: 'inline-block', marginLeft: '3px' }
    },
    [iconVnode, fileName]
  )

  return attachVnode
}

export default { audioAttachment, renderAudioAttachment }
