import { IDomEditor, IModalMenu, SlateNode } from '@wangeditor/editor'

class AudioUploader {
  constructor (props) {
    this.title = '上传音频'
    // this.iconSvg = '<svg >...</svg>'
    this.tag = 'button'
    this.uploadHandler = props.uploadHandler ?? null
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive (editor) { // JS 语法
    return false
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue (editor) { // JS 语法
    return ''
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled (editor) { // JS 语法
    return false
  }

  // 点击菜单时触发的函数
  exec (editor, value) { // JS 语法
    const inputFileEl = document.createElement('input')
    inputFileEl.type = 'file'
    inputFileEl.id = 'customFile'
    inputFileEl.click()
    inputFileEl.onchange = e => {
      const file = e.currentTarget.files[0]
      if (this.uploadHandler && file) {
        this.uploadHandler(file, (url) => {
          const audioContent = `<audio src="${url}" controls></audio></br>`
          // editor.dangerouslyInsertHtml(`<h1>${url}</h1></br>`)
          // editor.dangerouslyInsertHtml(audioContent)
          editor.insertNode({
            type: 'audio',
            children: [{ text: 'hello' }]
          })
          console.log(111111, editor.getHtml())
        })
      }
    }
  }
}
export default AudioUploader
