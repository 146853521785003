<script setup>
import { ref, reactive, defineProps, onBeforeMount } from 'vue'
import Editor from '@/components/common/Editor/Editor.vue'
import { getSortList, editAppreciation, createAppreciation } from '@/services/appreciation'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import MultiSongConfig from '@/components/song/MultiSongConfig.vue'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue'

const router = useRouter()

const goBack = () => {
  router.go(-1)
}

const props = defineProps({
  current: {
    type: Object,
    default: () => { return {} }
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})

const labelCol = { span: 4 }
const wrapperCol = { span: 17 }
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 4
    }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 20
    }
  }
}
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
}

const formRef = ref()
const editorRef = ref()

const formState = reactive({
  ...props.current,
  category_ids: props.current.categories && props.current.categories.length > 0
    ? props.current.categories.map(i => i.id) : [undefined],
  lyricists: props.current.lyricists ?? [''],
  composers: props.current.composers ?? ['']
})

const pageState = reactive({
  sortOpts: [],
  nodeList: []
})

const getSortData = async () => {
  const { items } = await getSortList()
  pageState.nodeList = items
  pageState.sortOpts = getTreeData(0, items)
  formState.category_ids = formState.category_ids.map(i => {
    return getNodeFullPath(i)
  })
  formState.category_id = getNodeFullPath(formState.category_id)
}

// 获取节点路径
const getNodeFullPath = (id, path = []) => {
  if (path.length === 0) {
    path.push(id)
  }
  const node = pageState.nodeList.filter(i => id === i.id)[0] ?? {}
  if (node.pid > 0) {
    path.unshift(node.pid)
    return getNodeFullPath(node.pid, path)
  }
  return path
}

// 构造菜单树数据
const getTreeData = (pid, menus) =>
  menus
    .filter(item => item.pid === pid)
    .map(item => {
      if (menus.filter(menu => menu.pid === item.id).length === 0) {
        return { label: item.name, value: item.id }
      }
      return {
        label: item.name,
        value: item.id,
        children: getTreeData(item.id, menus)
      }
    })

const handleUpdateList = info => {
  formState.resources = info
}

const handleSave = async () => {
  await formRef.value.validate()
  const categoryIds = formState.category_ids.map(i => i[i.length - 1]).filter(i => i)
  console.log(categoryIds)
  if (categoryIds.length === 0) {
    message.error('请选择分类')
    return
  }

  try {
    const params = {}
    // params.category_id = formState.category_id.at(-1)
    // params.category_ids = formState.category_ids.map(i => i.at(-1))
    params.category_id = formState.category_id[formState.category_id.length - 1]
    params.category_ids = formState.category_ids.map(i => i[i.length - 1])
    params.intros = editorRef.value.getContent()
    params.song_name = formState.song_name
    params.song_code = formState.song_code
    params.lyricists = formState.lyricists
    params.composers = formState.composers
    params.sub_name = formState.sub_name
    params.resources = formState.resources?.map(i => {
      delete i?.id
      delete i?.famous_music_song_id
      return i
    }) || []
    if (formState.id) {
      await editAppreciation(formState.id, params)
    } else {
      await createAppreciation(params)
    }
    message.success('保存成功')
    goBack()
  } catch (e) {
    console.error(e)
  }
}

const removeItem = (key = 'lyricists', item) => {
  const index = formState[key].indexOf(item)

  console.log(index, formState[key])
  if (index === 0 && formState[key].length === 1) {
    formState[key] = ['']
  } else {
    formState[key].splice(index, 1)
  }
}

const addItem = (key = 'lyricists') => {
  formState[key].push('')
}

onBeforeMount(() => {
  getSortData()
})

</script>
<template>
  <a-row>
    <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <a-form
        ref="formRef"
        :model="formState"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-form-item
          name="song_name"
          label="歌曲名称"
          :rules="[{ required: true, message: '请输入歌曲名称' }]"
        >
          <a-input v-model:value="formState.song_name" :disabled="props.readOnly" />
        </a-form-item>
        <a-form-item
          name="song_code"
          label="歌曲编码"
        >
          <a-input v-model:value="formState.song_code" :disabled="props.readOnly" />
        </a-form-item>
        <a-form-item
          v-for="(item, index) in formState.lyricists"
          :key="index"
          v-bind="index === 0 ? formItemLayout : formItemLayoutWithOutLabel"
          :label="index === 0 ? '作词' : ''"
          :name="['lyricists', index]"
        >
          <a-input
            v-model:value="formState.lyricists[index]"
            placeholder="请输入作词家"
            style="width: 60%; margin-right: 8px"
            :disabled="props.readOnly"
          />
          <MinusCircleOutlined
            v-if="!props.readOnly"
            class="dynamic-delete-button"
            @click="removeItem('lyricists', item)"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel" v-if="!props.readOnly">
          <a-button type="dashed" style="width: 60%" @click="addItem('lyricists')">
            <PlusOutlined />
            新增
          </a-button>
        </a-form-item>
        <a-form-item
          v-for="(item, index) in formState.composers"
          :key="index"
          v-bind="index === 0 ? formItemLayout : formItemLayoutWithOutLabel"
          :label="index === 0 ? '作曲' : ''"
          :name="['composers', index]"
        >
          <a-input
            v-model:value="formState.composers[index]"
            placeholder="请输入作曲家"
            style="width: 60%; margin-right: 8px"
            :disabled="props.readOnly"
          />
          <MinusCircleOutlined
            v-if="!props.readOnly"
            class="dynamic-delete-button"
            @click="removeItem('composers', item)"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel" v-if="!props.readOnly">
          <a-button type="dashed" style="width: 60%" @click="addItem('composers')">
            <PlusOutlined />
            新增
          </a-button>
        </a-form-item>
        <a-form-item
          name="sub_name"
          label="副标题"
        >
          <a-input v-model:value="formState.sub_name" :disabled="props.readOnly" />
        </a-form-item>

        <a-form-item
          class="form-item"
          v-for="(item, index) in formState.category_ids"
          :key="index"
          v-bind="index === 0 ? {} : {
            wrapperCol: {
                xs: {
                  span: 24,
                  offset: 0
                },
                sm: {
                  span: 17,
                  offset: 4
                }
              }
            }"
          :label="index === 0 ? '选择分类' : ''"
          :name="['category_ids', index]"
          :rules="[{ required: true, message: '请选择分类' }]"
        >
          <a-cascader
            :disabled="props.readOnly"
            change-on-select
            v-model:value="formState.category_ids[index]"
            :options="pageState.sortOpts"
            placeholder="请选择分类"
            expandTrigger="hover"
          />
          <MinusCircleOutlined
            v-if="!props.readOnly && formState.category_ids.length > 1"
            class="dynamic-delete-button form-item-minus"
            @click="removeItem('category_ids', item)"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel" v-if="!props.readOnly">
          <a-button type="dashed" style="width: 85%" @click="addItem('category_ids')">
            <PlusOutlined />新增
          </a-button>
        </a-form-item>

        <a-form-item label="歌曲配置">
          <MultiSongConfig
            @updateList="handleUpdateList"
            :formIsEdit="!props.readOnly"
            :list="formState.resources"
            :aliasConfig="{
              url: 'file_url',
              name: 'file_name',
              md5: 'file_md5',
              size: 'file_size'
            }"
          />
        </a-form-item>

        <a-form-item
          name="intros"
          label="简介"
          :rules="[{ required: false, message: '请输入简介' }]"
        >
          <editor ref="editorRef" :content="formState.intros" :readOnly="props.readOnly" />
        </a-form-item>

      </a-form>
    </a-col>
    <a-col :offset="11" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="!props.readOnly">
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-col>
  </a-row>
</template>
<style lang="less" scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.form-item{
  position: relative;
  .form-item-minus{
    position: absolute;
    top: 4px;
    right: -30px;
  }
}
</style>
