<script setup>
import { PlusOutlined } from '@ant-design/icons-vue'
import { computed, ref, toRaw, defineProps, defineEmits } from 'vue'
import SongUploadModal from './SongUploadModal.vue'

const emit = defineEmits(['updateList'])

const props = defineProps({
  list: {
    type: Array,
    default: () => { return [] }
  },
  formIsEdit: {
    type: Boolean,
    default: () => { return false }
  },
  aliasConfig: {
    type: Object,
    default: () => {
      return {
        url: 'file_url',
        name: 'file_name',
        md5: 'file_md5',
        size: 'file_size'
      }
    }
  }
})

const listComputed = computed(() => props.list)
const formIsEditComputed = computed(() => props.formIsEdit)

const current = ref({})
const visible = ref(false)
const isEdit = ref(false)

const labelCol = { span: 5 }
const wrapperCol = { span: 17 }
const currentIndex = ref(-1)

const handleRemoveItem = item => {
  const list = ref(toRaw(listComputed.value))
  const index = list.value.indexOf(item)
  if (index !== -1) {
    list.value.splice(index, 1)
  }
  emit('updateList', list)
}

const handleEditItem = (item, index) => {
  visible.value = true
  current.value = item
  currentIndex.value = index
  isEdit.value = true
}

const handleAddConfig = () => {
  isEdit.value = false
  current.value = {}
  visible.value = true
  currentIndex.value = -1
}

const handleModalCancel = () => {
  current.value = {}
  visible.value = false
  currentIndex.value = -1
}

const handleModalOk = info => {
  const list = ref(toRaw(listComputed.value))
  list.value.push(info)
  emit('updateList', list.value)
}

const handleModalEditOk = info => {
  const list = ref(toRaw(listComputed.value))
  list.value[currentIndex.value] = info
  emit('updateList', list.value)
}

</script>

<template>
  <div>
    <div v-for="(item, index) in listComputed" :key="index" class="card_body">
      <a-descriptions :title="`配置${index + 1}`" bordered :column="6">
        <template #extra>
          <a-space v-if="formIsEditComputed">
            <a @click="() => handleRemoveItem(item)">移除</a>
            <a @click="() => handleEditItem(item, index)">编辑</a>
          </a-space>
        </template>
        <a-descriptions-item label="歌曲名" :span="3">
          {{ item[props.aliasConfig.name] }}
        </a-descriptions-item>
        <a-descriptions-item label="歌曲MD5" :span="3">
          {{ item[props.aliasConfig.md5] }}
        </a-descriptions-item>
        <a-descriptions-item label="歌曲文件地址" :span="6">
          <a-tooltip
            v-if="item[props.aliasConfig.url].length > 90"
            :title="item[props.aliasConfig.url]"
          >{{ item[props.aliasConfig.url].slice(0, 90) }}...</a-tooltip
          >
          <span v-else>{{ item[props.aliasConfig.url] }}</span>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <a-button class="add-btn" @click="handleAddConfig" v-if="formIsEditComputed">
      <PlusOutlined />新增
    </a-button>
    <SongUploadModal
      v-if="visible"
      :visible="visible"
      :isEdit="isEdit"
      :value="current"
      @cancel="handleModalCancel"
      @onOk="handleModalOk"
      @onEditOk="handleModalEditOk"
      :aliasConfig="props.aliasConfig"
    />
  </div>
</template>

<style scoped>
.add-btn {
  width: 100%;
}

.card_body {
  margin-bottom: 10px;
}
</style>
